import { useEffect,useState } from "react";

const useVariantStyles = (variant:string,textColor:string) => {
    const [styles,setStyles] = useState<string>('');

    useEffect(() => {
        const getVariantStyles = () => {    
            switch(variant) {
                case 'bigHeading':
                    return `text-7xl font-bold ${textColor}`;
                case 'heading':
                    return `text-4xl font-bold ${textColor}`;
                case 'subheading':
                    return `text-2xl font-semibold ${textColor}`;
                case 'body':
                    return `text-base font-normal ${textColor}`;
                case 'caption':
                    return `text-sm font-normal ${textColor}`;
                case 'button':
                    return `text-base font-semibold ${textColor}`;
                case 'link':
                    return `text-blue-500 underline hover:underline ${textColor}`;
                default:
                    return '';
            }
        }

        setStyles(getVariantStyles());

    },[variant,textColor]);

    return styles;
}

export default useVariantStyles;