import HomeLayout from "../components/Layout/homeLayout";
import Intro from "../components/UI/intro";
import RecentWork from "../components/sections/recentWork";
import Skills from "../components/UI/skills/skills";
function Home(){
    return (
        <HomeLayout>
            <div className="container max-w-full space-y-3">
                <Intro />
                <RecentWork />
                <Skills />
            </div>
        </HomeLayout>
    )
}

export default Home;