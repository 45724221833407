import { Link } from "react-router-dom";
import React, { useState } from "react";
import Github from "../icons/github";
import LinkedIn from "../icons/linkedIn";
import SideMenu from "./sideMenu";
interface Props {}

const Header: React.FC<Props> = () => {
  const [isOpen, setIsOpen] = useState(false);
  const openDrawer = () => {
    setIsOpen((prev) => !prev);
  };
  return (
    <div className="container max-w-full">
      <nav className="bg-neutral p-4">
        <div className="container mx-auto flex justify-between items-center">
          {/* Logo or site name */}
          <Link to="/" className="text-white text-xl font-bold">
            Rajat Chauhan
          </Link>

          {/* Navigation Links */}
          <div className="hidden md:flex space-x-4">
            <Link to="/" className="text-white hover:text-gray-300">
              Home
            </Link>
            <a href="#projects" className="text-white hover:text-gray-300">
              Recent Work
            </a>
            <a href="#skills" className="text-white hover:text-gray-300">
              Skills
            </a>
            <a href="#footer" className="text-white hover:text-gray-300">
              Contact
            </a>
          </div>

          <div className="flex space-x-6">
            <Link
              to="https://github.com/rajatchauhan407"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Github />
            </Link>
            <Link
              to="https://www.linkedin.com/in/rajatchauhan1323"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LinkedIn />
            </Link>
          </div>

          {/* Mobile Menu Button */}
          <div className="md:hidden">
            <button
              className="text-white focus:outline-none"
              onClick={openDrawer}
            >
              <svg
                className="h-6 w-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                ></path>
              </svg>
            </button>
          </div>
        </div>
      </nav>
      {isOpen && <div className="fixed inset-0 bg-black bg-opacity-50 z-5" onClick={openDrawer}></div>}
      <div className="bg-neutral">
      {isOpen && <SideMenu 
        onClick={openDrawer}
      />}
      </div>
    </div>
  );
};

export default Header;
