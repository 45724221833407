import Typography from "../UI/typography"
import React from "react";
// import { useForm } from "react-hook-form";
import Input from "../UI/input/input";
import Button from "../UI/Buttons/button";
interface ContactFormProps{

}
const ContactForm:React.FC<ContactFormProps> = ()=>{

    const submitContactForm = ()=>{
        return;
    }
    return <>
        {/* <Typography
            variant="body"
            textColor="text-white"
        >
            Contact Form
        </Typography> */}

        <div className="flex flex-col w-full justify-center pb-5">
            <form onSubmit={submitContactForm} className="flex flex-col space-y-6">
                <Input
                    isLabel={true}
                    labelText="Email"
                    id="email"
                    type="email"
                    placeholder="Enter Your Email"
                    labelColor="text-white"
                    inputTextColor="text-black"
                    restOfClassesInput="rounded-sm my-2"
                />

                <Input
                    isLabel={true}
                    labelText="Mobile Number"
                    id="mobileNumber"
                    type="text"
                    placeholder="Enter Mobile Number"
                    labelColor="text-white"
                    inputTextColor="text-black"
                    restOfClassesInput="rounded-sm my-2"
                />
                <div className="flex flex-col">
                    <label className="text-white">Enter Your Message</label>
                    <textarea
                        className="rounded-sm my-2"
                        placeholder="Enter Your Message"
                        rows={5}
                        cols={10}
                    />
                </div>
            <Button
                variant="primary"
                restOfClasses="w-full">
                <Typography
                    variant="body"
                    textColor="text-white"
                    restOfClasses="p-3">
                        Submit
                    </Typography>
            </Button>
            </form>
        </div>
    </>
}
export default ContactForm;