import React from 'react';
import Xmark from '../../assets/icons/x-mark';
interface SideMenuProps {
    onClick: () => void;
}

const SideMenu:React.FC<SideMenuProps> = ({onClick}) => {

 return (
    <>
      
      <nav className="flex flex-col fixed top-0 right-0 w-64 h-screen bg-neutral text-white p-6 items-center z-10">

        <ul className='w-full'>
            <button className='flex justify-center p-4 w-full' onClick={onClick}>
                <Xmark/>
            </button>
          <li className="flex items-center mb-4" onClick={onClick}>
            <a href="#home" className="block py-2 px-4 rounded hover:bg-gray-700 w-full text-center">Home</a>
          </li>
          <li className="mb-4" onClick={onClick}>
            <a href="#projects" className="block py-2 px-4 rounded hover:bg-gray-700 text-center">Projects</a>
          </li>
          <li className="mb-4" onClick={onClick}>
            <a href="#footer" className="block py-2 px-4 rounded hover:bg-gray-700 text-center">Contact</a>
          </li>
        </ul>
      </nav>
      </>
 )
}

export default SideMenu;