import React from "react";
import Typography from "../typography";
import img from "../../../assets/images/rajat_profile_pic.jpg";
import Button from "../Buttons/button";
const Intro: React.FC = () => {
        return (
            <div className="flex flex-col-reverse md:flex-row md:p-10 bg-background" id="home"> 
                <div 
                    className="flex flex-col w-full px-10 pb-5 justify-center align-center " 
                    data-testid="intro"
                >
                <Typography 
                    variant="heading" 
                    fontFamily="Roboto,Arial,sans-serif" 
                    textColor="text-white"
                    restOfClasses="py-3 text-5xl"
                    >
                    Rajat Chauhan
                </Typography>
                <Typography 
                    variant="body"
                    fontFamily="Roboto,Arial,sans-serif"
                    textColor="text-gray-400"
                    restOfClasses="pb-5"
                >
                    As a full stack developer in Canada, I really enjoy keeping things clear and straightforward in the world of programming. Whether I'm working on strong back-end structures or creating user-friendly interfaces, I love making code that's organized and easy to understand.  
                </Typography>
                <Button 
                    variant="primary"
                    restOfClasses="py-4 w-3/4"
                    >
                    <Typography
                        variant="body"
                        fontFamily="Roboto,Arial,sans-serif"
                        textColor="text-white"   
                    >
                    Let's Get Started
                    </Typography>
                </Button>
                </div>
                <div className="flex flex-col w-full p-6 justify-center">
                    <img src={img} alt="profile_pic" className="rounded-full "/>
                </div>
            </div>
        )
}
export default Intro;