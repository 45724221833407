// Purpose: Footer section of website.
import React from 'react';
import Typography from '../UI/typography';
import ContactForm from './contactForm';

    interface FooterProps {

}

const Footer:React.FC<FooterProps> = () => {
    return (
        <footer className='flex flex-col items-center md:px-20'>
            <Typography
                variant='heading'
                fontFamily='Roboto,Arial,sans-serif'
                textColor='text-white'
                restOfClasses='items-center justify-center py-4'
            >
                Get In Touch
            </Typography>
            <Typography
                variant='body'
                fontFamily='Roboto,Arial,sans-serif'
                textColor='text-gray-400 w-full text-center'
                restOfClasses='items-center justify-center py-4'
            >
                I'd love to connect with you! Feel free to reach out through the contact form below or via email at techandtribe@gmail.com. I'm open to discussions, collaborations. I typically respond to emails within 24 hours. Looking forward to hearing from you!

            </Typography>
            <ContactForm/>
        </footer>
    )
}

export default Footer;