import React from "react";
// import logo from './logo.svg';
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/home";
function App() {
  return <Router>
    <AppRoutes />
  </Router>;
}

export function AppRoutes() {
  return (<Routes>
    <Route path="/" element={<Home />} />
    {/* <Route path="/home" element={<h1>Home</h1>} />
        <Route path="/contact" element={<h1>Contact</h1>} />
        <Route path="/about" element={<h1>About</h1>} />
        <Route path="/blog" element={<h1>Dashboard</h1>} /> */}
  </Routes>);
}
export default App;
