import React from 'react';

        interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>{
            variant?: "primary" | "secondary" | "danger" | "success";
            children: React.ReactNode;
            restOfClasses?: string;
            
        }

const Button:React.FC<ButtonProps> = ({children,variant,restOfClasses, ...props})=>{
    return (
        <button 
            className={
                `bg-primary 
                 text-white 
                 font-bold 
                 rounded 
                 ${restOfClasses}`}
            {...props}
        >
            {children}
        </button>
    )
}

export default Button;