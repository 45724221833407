import React from "react";
import useVariantStyles from "../../../hooks/useVariant";

interface TypographyProps {
    children: React.ReactNode;
    variant:'bigHeading' | 'heading' | 'subheading' | 'body' | 'caption' | 'button' | 'link';
    fontFamily?:string;
    textColor:string;
    restOfClasses?:string;
}
const Typography: React.FC<TypographyProps> = ({variant,fontFamily,children,textColor, restOfClasses}) => {
    
const typographyStyles = useVariantStyles(variant,textColor);
    
    return (
            <div 
                className={`${typographyStyles} ${restOfClasses}`} 
                style={{fontFamily}} 
                >
                {children}
            </div>
        )
}

export default Typography;