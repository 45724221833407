import React from "react";
import WorkCard from "../UI/workcard";
import toysTresure from "../../assets/images/toysTreasure.png";
import ECO from "../../assets/images/ECO.png";
import Typography from "../UI/typography";
import Safe from "../../assets/images/safe.png";
const RecentWork: React.FC = () => {
  const recentWork = [
    {
      imgSource: Safe,
      projectTitle: "Safe",
      forwardLink: "https://www.safesitealert.com",
      description:
        "Safe is a platform that connects victims of domestic violence with support services. It provides a secure and confidential space for victims to seek help and access resources. Safe also offers a community forum where survivors can share their stories and connect with others who have experienced similar situations."
    },
    {
      imgSource: toysTresure,
      projectTitle: "Toys Treasure",
      forwardLink: "https://toystreasure-50c4d.web.app",
      description: "At Toys Treasure, our mission is to connect donors and charities, unlocking happiness through donations. Together, we bring joy to every child, ensuring that each toy finds its way into the hands of those who need it the most.",
    },
    {
      imgSource: ECO,
      projectTitle: "ECO - Educational Career Orientation",
      forwardLink: "https://ecocareer.app",
      description:
        "Our user-friendly web platform helps high school students in Latin America who want to make informed career choices by providing counselors with data insights into students' skills and personality tests. This alleviates the confusion and uncertainty students often face when deciding on a career path and enables them to make confident decisions. Unlike traditional methods, which may rely on paper-based assessments.",
    },
  ];

  return (
    <div className="flex flex-col items-center my-4 md:px-20" id="projects">
      <Typography
        variant="heading"
        fontFamily="Roboto"
        textColor="text-black"
        restOfClasses="text-center"
      >
        Recent Projects
      </Typography>
      <div className="flex w-full px-6">
        <Typography
          variant="body"
          fontFamily="Roboto"
          textColor="text-black"
          restOfClasses="text-center my-5"
        >
          My recent work showcases my proficiency in addressing challenges
          across both frontend and backend development. I thrive on crafting
          comprehensive solutions and love the dynamic nature of problem-solving
          in the ever-evolving tech landscape. Explore my portfolio to see how I
          bring creativity and expertise to diverse projects.
        </Typography>
      </div>
      <div className="flex flex-col md:flex-row justify-center w-full px-6">
        {recentWork.map((work, index) => {
          return (
            <WorkCard
              key={index}
              imgSource={work.imgSource}
              projectTitle={work.projectTitle}
              description={work.description}
              forwardLink={work.forwardLink}
            />
          );
        })}
      </div>
    </div>
  );
};

export default RecentWork;
