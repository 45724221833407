import React from 'react'
import Typography from '../typography'
import Button from '../Buttons/button'
interface WorkCardProps{
    imgSource:string,
    projectTitle:string,
    description:string,
    forwardLink?:string
}
const WorkCard:React.FC<WorkCardProps> = ({imgSource,projectTitle,description,forwardLink})=>{
    return(
        <div className="flex flex-col w-full sm:w-1/2 lg:px-3 lg:py-3 my-6">
            <a href={forwardLink}>
            <img 
                src={imgSource} alt={projectTitle} 
                className='rounded-lg shadow w-full h-64 object-contain'  

                />
            </a>
            
            <Typography
                variant="heading"
                fontFamily="Roboto,Arial,sans-serif"
                textColor="text-neutral"
                restOfClasses="py-3"
            >
                {projectTitle}
            </Typography>
            <Typography
                variant="body"
                fontFamily="Roboto,Arial,sans-serif"
                textColor="text-gray-400"
                restOfClasses="pb-5">
                {description}
            </Typography>
            <a href={forwardLink}>
            <Button 
                restOfClasses='w-1/2'    
            >
                <Typography
                    variant="body"
                    fontFamily="Roboto,Arial,sans-serif"
                    textColor="text-white"
                    restOfClasses="py-3">
                  Know More &nbsp; &gt;
                </Typography>
            </Button>
            </a>
        </div>
    )
}

export default WorkCard;