import React from 'react';
// import { Link } from 'react-router-dom';
import Header from '../sections/header';
import Footer from "../sections/footer";
interface LayoutProps{
    children:React.ReactNode
}

const HomeLayout:React.FC<LayoutProps> = ({children}) => {
    return (
        <div className='flex flex-col'>
            <div className='flex sticky top-0'>
                <Header/>
            </div>
            <div className='flex w-full '>
                {children}
            </div>
            <div className='flex w-full bg-background px-6' id='footer'>
                <Footer/>
            </div>
        </div>
    );
}
export default HomeLayout;