import React from 'react';
import Typography from '../typography';

interface SkillCardProps {
    skill: string;
    Icon?: React.FC;
}



const SkillCard:React.FC<SkillCardProps> = ({ skill,Icon}) => {

    return (
        <div className="flex flex-col w-full items-center" data-testid='skill-card'>
            <div>
                {Icon && <Icon/>}
            </div>
            <Typography
                variant="body"
                fontFamily="Roboto,Arial,sans-serif"
                textColor="text-gray-400"
                restOfClasses="py-3 text-center">
                {skill}
            </Typography>
        </div>
    )
  }

  export default SkillCard;