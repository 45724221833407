import React from "react";
import { IoLogoJavascript } from "react-icons/io5";
import { FaReact } from "react-icons/fa";
import { RiNextjsFill } from "react-icons/ri";
import SkillCard from "../skillCard/skillCard";
import Typography from "../typography";
import { SiTypescript } from "react-icons/si";
import { FaNodeJs } from "react-icons/fa";
import { SiRedux } from "react-icons/si";
import { DiMongodb } from "react-icons/di";
import { TbBrandMysql } from "react-icons/tb";
import { FaAws } from "react-icons/fa";
import { FaDocker } from "react-icons/fa";
import { SiCypress } from "react-icons/si";
import { SiNginx } from "react-icons/si";
import { RiTailwindCssFill } from "react-icons/ri";

const Skills: React.FC = () => {
  const skillsArray = [
    {
      skill: "Node.js",
      Icon: () => {
        return <FaNodeJs size={48} style={{ color: "#3C873A" }} />;
      },
    },
    {
      skill: "Javascript",
      Icon: () => {
        return <IoLogoJavascript size={48} style={{ color: "yellow" }} />;
      },
    },
    {
      skill: "React",
      Icon: () => {
        return <FaReact size={48} style={{ color: "#00FFFF" }} />;
      },
    },
    {
      skill: "Next.js",
      Icon: () => {
        return <RiNextjsFill size={48} style={{ color: "#333333" }} />;
      },
    },
    {
      skill: "Typescript",
      Icon: () => {
        return <SiTypescript size={48} style={{ color: "#333333" }} />;
      },
    },
    {
      skill: "Redux",
      Icon: () => {
        return <SiRedux size={48} style={{ color: "#764ABC" }} />;
      },
    },
    {
      skill: "MongoDB",
      Icon: () => {
        return <DiMongodb size={48} style={{ color: "#13AA52" }} />;
      },
    },
    {
      skill: "MySQL",
      Icon: () => {
        return <TbBrandMysql size={48} style={{ color: "#00758F" }} />;
      },
    },
    {
      skill: "AWS",
      Icon: () => {
        return <FaAws size={48} style={{ color: "#FF9900" }} />;
      },
    },
    {
      skill: "Docker",
      Icon: () => {
        return <FaDocker size={48} style={{ color: "#2496ED" }} />;
      },
    },
    {
      skill: "Cypress",
      Icon: () => {
        return <SiCypress size={48} style={{ color: "#17202C" }} />;
      },
    },
    {
      skill: "Nginx",
      Icon: () => {
        return <SiNginx size={48} style={{ color: "#009639" }} />;
      }
    },
    {
      skill: "Tailwind CSS",
      Icon: () => {
        return <RiTailwindCssFill size={48} style={{ color: "#06B6D4" }} />;
      },
    },

  ];

  return (
    <div className="w-full flex flex-col px-6 py-6 md:px-28" id="skills">
      <Typography variant="heading" fontFamily="Roboto" textColor="text-black">
        Skills
      </Typography>
      <div className="grid grid-cols-3 gap-2 box-border">
        {skillsArray.map((sk, index) => {
          return (
            <div 
              className="w-full  shadow-lg p-3 rounded-lg"
              key={index}
              >
              <SkillCard key={index} skill={sk.skill} Icon={sk.Icon} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Skills;
