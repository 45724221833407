
import React from 'react';
interface InputProps extends React.InputHTMLAttributes<HTMLInputElement>{
    labelText:String,
    isLabel:boolean
    labelColor?:string,
    inputTextColor?:string,
    restOfClassesInput?:string
}


const Input:React.FC<InputProps> = ({isLabel,labelText,id,labelColor,inputTextColor,restOfClassesInput,...props}) => {
    
    return <div className='flex flex-col'>
        { isLabel && 
            <label 
                htmlFor={id}
                className={labelColor?labelColor:'text-white'}
            > {labelText} </label> 
        }
        <input 
            id={id}
            className={`${inputTextColor?inputTextColor:'text-black'} ${restOfClassesInput}`}
            {...props} 
        />
    </div>
}

export default Input;